


























































































































import mixins from 'vue-typed-mixins'
import FlexibleBookingWeeklyCalendar from '@/builder/sections/section/shared/mixins/FlexibleBookingWeeklyCalendar'
import TimeZoneInput from '@/builder/sections/shared/TimeZoneInput.vue'
import LocationInput from '@/builder/sections/shared/LocationInput.vue'

export default mixins(FlexibleBookingWeeklyCalendar).extend({
  name: 'FlexibleBooking1',
  components: { LocationInput, TimeZoneInput }
})
