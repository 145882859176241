



























import Vue from 'vue'
import moment from 'moment/moment'

export default Vue.extend({
  name: 'TimeZoneInput',
  props: {
    draftConfiguration: {
      type: Object
    },
    isTimeZoneHidden: {
      type: Boolean
    }
  },
  data () {
    return {
      customerTimeZone: moment.tz.guess()
    }
  }
})
