













import Vue from 'vue'

export default Vue.extend({
  name: 'LocationInput',
  props: {
    draftConfiguration: {
      type: Object
    },
    dense: {
      type: Boolean,
      default: false
    }
  }
})
