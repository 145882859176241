import mixins from 'vue-typed-mixins'
import Section from '@/builder/sections/mixins/Section'
import moment from 'moment'

export default mixins(Section).extend({
  data () {
    return {
      selectedDate: null as string | null,
      minDate: new Date().toISOString().substr(0, 10),
      weekday: [1, 2, 3, 4, 5, 6, 0],
      now: moment(),
      customerTimeZone: moment.tz.guess()
    }
  },
  computed: {
    isToday (): boolean {
      return this.$moment(this.selectedDate, this.$helpers.dateFormat).startOf('day')
        .isSame(this.$moment().startOf('day'))
    },
    isFullWidth (): boolean {
      return !!this.data?.configuration.wb_container_fluid__checkbox__
    }
  }
})
