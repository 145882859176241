import mixins from 'vue-typed-mixins'
import timeToNumber from '@/calendesk/tools/timeToNumber'
import FlexibleBooking from '@/builder/sections/section/shared/mixins/FlexibleBooking'

export default mixins(FlexibleBooking).extend({
  data () {
    return {
      firstTime: '08:00',
      intervalCount: 24,
      intervalMinutes: 60,
      events: [] as Array<any>,
      isReady: false,
      lastReloadData: null as any
    }
  },
  computed: {
    getType (): string {
      if (!this.isMobile) {
        return 'week'
      }

      return 'day'
    },
    datesRange (): string | null {
      if (this.lastReloadData) {
        const start = this.$moment(this.lastReloadData.start.date, this.$helpers.dateFormat).format('Do MMM')
        const end = this.$moment(this.lastReloadData.end.date, this.$helpers.dateFormat).format('Do MMM')

        return `${start} - ${end}`
      }

      return null
    }
  },
  watch: {
    'data.configuration' () {
      this.setupCalendar()
    }
  },
  created () {
    this.selectedDate = this.$moment().format(this.$helpers.dateFormat)
    this.setupCalendar()
    this.generateTestEvents()
    this.isReady = true
  },
  methods: {
    reloadEvents (data?: any) {
      this.lastReloadData = data
    },
    generateTestEvents () {
      const events = []
      for (let i = 0; i < 6; i++) {
        events.push({
          start: this.$moment().format(`YYYY-MM-DDT1${i}:00`),
          end: this.$moment().format(`YYYY-MM-DDT1${i + 1}:00`),
          color: i === 0 ? 'success' : this.backgroundColor1,
          duration: 60
        })

        events.push({
          start: this.$moment().subtract(1, 'day').format(`YYYY-MM-DDT1${i}:00`),
          end: this.$moment().subtract(1, 'day').format(`YYYY-MM-DDT1${i + 1}:00`),
          color: i === 0 ? 'success' : this.backgroundColor1,
          duration: 60
        })

        events.push({
          start: this.$moment().subtract(2, 'day').format(`YYYY-MM-DDT1${i}:00`),
          end: this.$moment().subtract(2, 'day').format(`YYYY-MM-DDT1${i + 1}:00`),
          color: this.backgroundColor1,
          duration: 60
        })
      }

      this.events = events
    },
    setupCalendar () {
      this.generateTestEvents()

      const startCalendarTime = this.data.configuration.wb_start_calendar_time__time__
      const endCalendarTime = this.data.configuration.wb_end_calendar_time__time__
      const intervalMinutes = this.data.configuration.wb_interval_minutes__number__

      const startTimeNumber = timeToNumber(startCalendarTime)
      const endTimeNumber = timeToNumber(endCalendarTime)

      this.firstTime = startCalendarTime
      this.intervalCount = (endTimeNumber - startTimeNumber) / intervalMinutes
      this.intervalMinutes = intervalMinutes
    }
  }
})
